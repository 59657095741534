import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup, ToggleButton, Row, Col } from "react-bootstrap";

function DropdownEle(props) {
  const [selectedTz, setSelectedTz] = useState("");
  const handleSelectTzChange = (event) => {
    setSelectedTz(event.target.value);
  };

  const labelStyle = {
    color: props.isDisabled ? "grey" : "black",
  };

  return (
    <Form style={{ marginTop: 10 }}>
      <Row>
        <Col sm={10}>
          <Row>
            <label className="toogleEleTitle" style={labelStyle}>
              {props.title}
            </label>
          </Row>
          <Row>
            <label
              style={{
                color: "#717171",
                fontSize: 12,
                fontFamily: "GilroyLight",
              }}
            >
              {" "}
              {props.desp}{" "}
            </label>
          </Row>
        </Col>
        <Col sm={2}>
          <select
            disabled={props.isDisabled ? true : false}
            className="col-md-12 set-hg"
            style={{
              borderRadius: 4,
              height: 20,
              color: "grey",
              fontSize: 12,
              marginTop: 13,
            }}
            id="tz"
            name="tz"
            value={selectedTz}
            onChange={handleSelectTzChange}
          >
            <option value="USD">USD</option>
            <option value="USD">USD</option>
            <option value="USD">USD</option>
            <option value="USD">USD</option>
          </select>
        </Col>
      </Row>
    </Form>
  );
}

export default DropdownEle;
