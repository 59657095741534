import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./Verification.css";

function Verification(props) {
  const navigate = useNavigate();
  const navigateToProfileSetup = () => {
    navigate("/profilesetup"); // Navigate to the About page
  };

  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");

  return (
    <div className="verif_container">
      <div className="justify-content-center align-items-center ">
        <h4 className="code-title" style={{ fontSize: 16 }}>
          <br />
          {props.title}
        </h4>
        <p className="code-desc" style={{ fontSize: 12 }}>
          {props.desp}
        </p>
        <div className="d-flex code">
          <input
            style={{ height: 50, width: 50 }}
            className="digit"
            // height="40px"
            // width="40px"
            // type="number"
            value={digit1}
            onChange={(e) => setDigit1(e.target.value)}
            maxLength="1"
          />
          <input
            style={{ height: 50, width: 50 }}
            className="digit"
            // height="40px"
            // width="40px"
            // type="number"
            value={digit2}
            onChange={(e) => setDigit2(e.target.value)}
            maxLength="1"
          />
          <input
            style={{ height: 50, width: 50 }}
            className="digit"
            // height="40px"
            // width="40px"
            // type="number"
            value={digit3}
            onChange={(e) => setDigit3(e.target.value)}
            maxLength="1"
          />
          <input
            style={{ height: 50, width: 50 }}
            className="digit"
            // height="40px"
            // width="40px"
            // type="number"
            value={digit4}
            onChange={(e) => setDigit4(e.target.value)}
            maxLength="1"
          />
          <div className="d-flex offset-md-3" style={{ marginTop: -15 }}>
            <button
              onClick={() => navigate("/kyc")}
              className="verify-button"
              style={{
                border: "1px solid #D2D4C8",
                borderColor: "#D2D4C8",
                backgroundColor: "white",
                marginRight: 10,
                fontFamily: "GilroyLight",
                height: 40,
                width: 120,
              }}
            >
              Cancel
            </button>
            <button
              className="verify-button"
              style={{ fontFamily: "GilroyLight", height: 40, width: 120 }}
            >
              Verify
            </button>
          </div>
        </div>
        <p
          className="resend-code"
          style={{ fontSize: 14, color: "black", fontFamily: "GilroyLight" }}
        >
          Didn't get a code?{" "}
          <a href="" style={{ color: "gray" }}>
            Click to resend
          </a>
        </p>
      </div>
    </div>
  );
}

export default Verification;
