import React from "react";
import HomepageNavBar from "../../components/Homepage1/Homepage1Navbar/HomepageNavBar";
import SettingsHeader from "../../components/SettingsPage/SettingsHeader/SettingsHeader";
import SettingsLeftPanel from "../../components/SettingsPage/SettingsPageLeftPanel/SettingsLeftPanel";
import UploadVideoPage from "../../components/UploadVideoPage/UploadVideoPage";

function UploadVideo() {
  return (
    <div className="chats">
      <HomepageNavBar />
      <SettingsHeader heading="KYC Verification" />
      <div
        className="d-flex"
        style={{
          backgroundColor: "#D2D4C8",
          marginTop: 10,
          minHeight: "100vh",
        }}
      >
        <SettingsLeftPanel tab={4} />
        <div>
          <UploadVideoPage />
        </div>
      </div>
    </div>
  );
}

export default UploadVideo;
