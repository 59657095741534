import React from "react";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import ToggleElement2 from "./ToogleElement2";
import DropdownEle from "./DropdownEle";
import ButtonEle from "./ButtonsEle";
import { useState } from "react";

function PaymentReveneuPage() {
  const [isToggleEnabled, setToggleEnabled] = useState(false);

  const handleToggle = () => {
    setToggleEnabled(!isToggleEnabled);
  };

  const [isToggleEnabled2, setToggleEnabled2] = useState(false);

  const handleToggle2 = () => {
    setToggleEnabled2(!isToggleEnabled2);
  };

  const [isToggleEnabled3, setToggleEnabled3] = useState(false);

  const handleToggle3 = () => {
    setToggleEnabled3(!isToggleEnabled3);
  };

  const [isToggleEnabled4, setToggleEnabled4] = useState(false);

  const handleToggle4 = () => {
    setToggleEnabled4(!isToggleEnabled4);
  };

  const [isToggleEnabled5, setToggleEnabled5] = useState(false);

  const handleToggle5 = () => {
    setToggleEnabled5(!isToggleEnabled5);
  };
  const [isToggleEnabled6, setToggleEnabled6] = useState(false);

  const handleToggle6 = () => {
    setToggleEnabled6(!isToggleEnabled6);
  };
  const [isToggleEnabled7, setToggleEnabled7] = useState(false);

  const handleToggle7 = () => {
    setToggleEnabled7(!isToggleEnabled7);
  };
  const [isToggleEnabled8, setToggleEnabled8] = useState(false);

  const handleToggle8 = () => {
    setToggleEnabled8(!isToggleEnabled8);
  };

  return (
    <div className="col-md-8">
      <div
        className="info-cont notificationSettingContainer"
        style={{ paddingBottom: 20 }}
      >
        <ToggleElement2
          title="Fiat Currency"
          desp="Make your payments and receive your
        earning in fiat currency of your choice."
          isDisabled={isToggleEnabled}
          onToggle={handleToggle}
        />
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <DropdownEle
          title="Preferred Fiat Currency"
          desp="Select your preferred fiat currency for all the payments"
          isDisabled={isToggleEnabled}
        />
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ paddingBottom: 20, marginTop: 20 }}
      >
        <ToggleElement2
          title="Crypto Currency"
          desp="Diversify payment options with accepting 
        and making payments"
          isDisabled={isToggleEnabled2}
          onToggle={handleToggle2}
        />
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <DropdownEle
          title="Preferred Crypto Currency"
          desp="Select your preferred crypto currency for all the payments"
          isDisabled={isToggleEnabled2}
        />
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">Common Payment Settings</p>
        <p className="notf-desp">
          Fine-tune your payment process with our array of Common Payment
          Settings. These setting will apply to both crypto and fiat currency
          payments.
        </p>
        <div>
          <ToggleElement2
            title="Instant Payouts"
            desp="Instant payouts for a fee, providing a quick and convenient access to their funds"
            isDisabled={isToggleEnabled3}
            onToggle={handleToggle3}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Multi-Currency Display"
            desp="View prices in multiple currencies"
            isDisabled={isToggleEnabled8}
            onToggle={handleToggle8}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Two-Factor Authentication"
            desp="Enhance security with two-factor authentication for all payment transactions"
            isDisabled={isToggleEnabled7}
            onToggle={handleToggle7}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Automatic Invoice Generation"
            desp="Automatically generate invoices for completed transactions"
            isDisabled={isToggleEnabled6}
            onToggle={handleToggle6}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonEle
            title="Transaction Limits"
            desp="Define maximum and minimum transaction limits"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <DropdownEle
            title="Payment Method Preferences"
            desp="Set preferred payment methods between fiat and crypto"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Auto-Conversion"
            desp="Automatically convert incoming cryptocurrencies to fiat currency"
            isDisabled={isToggleEnabled5}
            onToggle={handleToggle5}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div style={{ marginBottom: 20 }}>
          <ToggleElement2
            title="Blockchain Explorer Links"
            desp="Include direct links to blockchain explorers for each transaction"
            isDisabled={isToggleEnabled4}
            onToggle={handleToggle4}
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentReveneuPage;
