import React from "react";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup, ToggleButton } from "react-bootstrap";
import ToggleElement2 from "./../PaymentReveneuPage/ToogleElement2";
import DropdownEle from "./../PaymentReveneuPage/DropdownEle";
import ButtonEle from "./../PaymentReveneuPage/ButtonsEle";
import { useState } from "react";
import ButtonElement from "./ButtonElement";

function Securitypage() {
  const [isToggleEnabled, setToggleEnabled] = useState(false);

  const handleToggle = () => {
    setToggleEnabled(!isToggleEnabled);
  };

  const [isToggleEnabled2, setToggleEnabled2] = useState(false);

  const handleToggle2 = () => {
    setToggleEnabled2(!isToggleEnabled2);
  };

  const [isToggleEnabled3, setToggleEnabled3] = useState(false);

  const handleToggle3 = () => {
    setToggleEnabled3(!isToggleEnabled3);
  };

  const [isToggleEnabled4, setToggleEnabled4] = useState(false);

  const handleToggle4 = () => {
    setToggleEnabled4(!isToggleEnabled4);
  };

  const [isToggleEnabled5, setToggleEnabled5] = useState(false);

  const handleToggle5 = () => {
    setToggleEnabled5(!isToggleEnabled5);
  };
  const [isToggleEnabled6, setToggleEnabled6] = useState(false);

  const handleToggle6 = () => {
    setToggleEnabled6(!isToggleEnabled6);
  };
  const [isToggleEnabled7, setToggleEnabled7] = useState(false);

  const handleToggle7 = () => {
    setToggleEnabled7(!isToggleEnabled7);
  };
  const [isToggleEnabled8, setToggleEnabled8] = useState(false);

  const handleToggle8 = () => {
    setToggleEnabled8(!isToggleEnabled8);
  };

  return (
    <div className="col-md-8">
      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">Security Settings</p>
        <p className="notf-desp">
          Elevate the security of your experience these powerful settings.
          Empower yourself with tools that fortify account access, monitor
          activity, and ensure a secure online presence.
        </p>
        <div style={{ marginTop: 20 }}>
          <ToggleElement2
            title="Two-Factor Authentication (2FA)"
            desp="Strengthen your account security by enabling two-factor authentication"
            isDisabled={isToggleEnabled3}
            onToggle={handleToggle3}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Account Activity Alerts"
            desp="Receive notifications for any unusual or unauthorized activities related to your account."
            isDisabled={isToggleEnabled8}
            onToggle={handleToggle8}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Data Usage Permissions"
            desp="Allow platform to use data for analytics or marketing purposes. For further details Terms & Policy "
            isDisabled={isToggleEnabled7}
            onToggle={handleToggle7}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Sensitive Data Encryption"
            desp="Secure sensitive information by enabling encryption for stored data like payment details"
            isDisabled={isToggleEnabled6}
            onToggle={handleToggle6}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <ToggleElement2
          title="End-to-End Messaging Encryption"
          desp="Enable encryption for messaging between users and groups"
          isDisabled={isToggleEnabled}
          onToggle={handleToggle}
        />
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Authenticator App"
            desp="Protect your account and transactions with authenticator app passcode "
            buttonType="Manage"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Passkeys"
            desp="Protect your account and withdrawals with a security key such as Yubikey"
            buttonType="Manage"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Device Management"
            desp="Manage devices allowed to access your account."
            buttonType="Manage"
          />
        </div>
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">Privacy Settings</p>
        <p className="notf-desp">
          Elevate your privacy experience using these tailored settings. Decide
          who sees your information, manage data usage permissions, and enjoy a
          confidential online presence.
        </p>
        <div style={{ marginTop: 20 }}>
          <ToggleElement2
            title="Data Usage Permissions"
            desp="Control how your data is used for analytics or marketing purposes"
            isDisabled={isToggleEnabled4}
            onToggle={handleToggle4}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ToggleElement2
            title="Third-Party App Permissions"
            desp="Review and manage permissions granted to third-party apps"
            isDisabled={isToggleEnabled5}
            onToggle={handleToggle5}
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Activity History"
            desp="Access a log of your account activities and interactions"
            buttonType="Review"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Privacy Policy Review"
            desp="Review platforms privacy policy"
            buttonType="Review"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="User Block List"
            desp="Manage a list of blocked users."
            buttonType="Manage"
          />
        </div>
      </div>
    </div>
  );
}

export default Securitypage;
