import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import UploadDocComponent from "../UploadDocumentsPage/UploadDocComponent";
import Form from "react-bootstrap/Form";

function UploadVideoPage() {
  const navigate = useNavigate();
  const navigateToProfileSetup = () => {
    navigate("/profilesetup"); // Navigate to the About page
  };

  return (
    <div
      className="verif_container col-md-11 p-2"
      style={{
        marginTop: 20,
      }}
    >
      <div className="justify-content-center align-items-center ">
        <h4 className="code-title" style={{ fontSize: 16, marginTop: 0 }}>
          <br />
          Upload documents
        </h4>
        <p className="code-desc" style={{ fontSize: 12 }}>
          Please upload back and front side of the your government-issued
          identification document
        </p>
        <div className="col-md-12">
          <UploadDocComponent
            title=""
            desp="Your video should include Name, Address, Country, Age, 
            owner or creator of project ( include project title) "
            supported="Supported: avi, mkv, mp4"
          />
        </div>
        <Form className="p-3">
          <Form.Check
            style={{
              fontFamily: "GilroyLight",
              color: "black",
              fontSize: 12,
              borderColor: "black",
            }}
            checked
            type="checkbox"
            id="custom-checkbox"
            label="I agree with the Terms and Policy. 
        I confirm that I have read the provided description and will abide by it."
          />
        </Form>
        <button
          className="p-1 col-md-12 mt-3"
          style={{
            borderColor: "transparent",
            backgroundColor: "#fee500",
            color: "black",
            fontFamily: "GilroyBold",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default UploadVideoPage;
