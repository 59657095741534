import React from "react";
import HomepageNavBar from "../../components/Homepage1/Homepage1Navbar/HomepageNavBar";
import SettingsHeader from "../../components/SettingsPage/SettingsHeader/SettingsHeader";
import SettingsLeftPanel from "../../components/SettingsPage/SettingsPageLeftPanel/SettingsLeftPanel";
import KycVerification from "../../components/KycVerification/KycVerification";
import Verification from "../../components/Verification/Verification";
import UploadDocumentPage from "../../components/UploadDocumentsPage/UploadDocumentPage";

function UploadDocument() {
  return (
    <div className="chats">
      <HomepageNavBar />
      <SettingsHeader heading="KYC Verification" />
      <div
        className="d-flex"
        style={{
          backgroundColor: "#D2D4C8",
          marginTop: 10,
          minHeight: "100vh",
        }}
      >
        <SettingsLeftPanel tab={4} />
        <div>
          <UploadDocumentPage />
        </div>
      </div>
    </div>
  );
}

export default UploadDocument;
