import React, { useState } from "react";
import ButtonElement from "../SecurityPage/ButtonElement";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import tick1 from "../../Images/tick-circle.png";
import tick2 from "../../Images/tick-circle2.png";
import Popup, { PopupboxManager } from "reactjs-popup";
import EmailVerification1Page from "../EmailVerification1Page/EmailVerification1Page";
import { Navigate, useNavigate } from "react-router-dom";

function KycVerification() {
  const navigate = useNavigate();
  const [isEmailPopupOpen, setIsEmailPopupOpen] = useState(false);

  const openEmailPopup = () => {
    console.log("Opening Email Popup");
    setIsEmailPopupOpen(true);
  };

  const closeEmailPopup = () => {
    console.log("Closing Email Popup");
    setIsEmailPopupOpen(false);
  };

  return (
    <div className="col-md-8">
      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">KYC Verification</p>
        <p className="notf-desp">
          Verify your identity to get best of the platform. Clear different
          level of verification to secure your account. Verifying your identity
          will enhance trust.
        </p>
        <div style={{ marginTop: 20 }}>
          <Row>
            <Col>
              <div className="d-flex">
                <img src={tick1} alt="" height="25px" width="25px" />
                <p
                  style={{
                    fontFamily: "GilroyBold",
                    fontSize: 12,
                    marginTop: 4,
                  }}
                >
                  Basic Verification
                </p>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <img src={tick2} alt="" height="25px" width="25px" />
                <p
                  style={{
                    fontFamily: "GilroyBold",
                    fontSize: 12,
                    marginTop: 4,
                  }}
                >
                  ID Verification
                </p>
              </div>
            </Col>
            <Col>
              <div className="d-flex">
                <img src={tick2} alt="" height="25px" width="25px" />
                <p
                  style={{
                    fontFamily: "GilroyBold",
                    fontSize: 12,
                    marginTop: 4,
                  }}
                >
                  In-depth Verification
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">Basic Verification</p>
        <p className="notf-desp">
          Verify your email address and contact number for basic level KYC
          verification.
        </p>
        <div onClick={openEmailPopup}>
          <ButtonElement
            title="Email"
            desp="A verification code will be sent to your provided email."
            buttonType="Verified"
          />
        </div>
        <Popup
          open={isEmailPopupOpen}
          onClose={closeEmailPopup}
          contentStyle={{ width: "25%" }}
        >
          <EmailVerification1Page />
        </Popup>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div onClick={() => navigate("/phoneVerification")}>
          <ButtonElement
            title="Phone number"
            desp="A verification code will be sent to your provided phone number."
            buttonType="Verified"
          />
        </div>
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">ID Verification</p>
        <p className="notf-desp">
          Verify your identity by submitting a valid government-issued
          identification document along side the paid electricity bill.
        </p>
        <div>
          <ButtonElement
            title="Identification Document"
            desp="Submit back and front side of the your government-issued identification document"
            buttonType="Submit"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Electricity Bill"
            desp="Submit recently paid electricity bill"
            buttonType="Submit"
          />
        </div>
      </div>

      <div
        className="info-cont notificationSettingContainer"
        style={{ marginTop: 20 }}
      >
        <p className="notf-title">In-depth Verification</p>
        <p className="notf-desp">
          Provide additional identity verification by uploading a clear image of
          your passport and recording a short video.
        </p>
        <div>
          <ButtonElement
            title="Passport"
            desp="Submit clear image of your passport"
            buttonType="Submitted"
          />
        </div>
        <div style={{ backgroundColor: "#ccc", height: 1, marginTop: 20 }} />

        <div>
          <ButtonElement
            title="Recorded Video"
            desp="Record a video of yourself for verification"
            buttonType="Submitted"
          />
        </div>
      </div>
    </div>
  );
}

export default KycVerification;
