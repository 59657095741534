import React from "react";
import SettingsHeader from "../../components/SettingsPage/SettingsHeader/SettingsHeader";
import HomepageNavBar from "../../components/Homepage1/Homepage1Navbar/HomepageNavBar";
import SettingsLeftPanel from "../../components/SettingsPage/SettingsPageLeftPanel/SettingsLeftPanel";
import NotificationSetting from "../../components/NotificationSettingPage/NotificationSetting";
import PaymentReveneuPage from "../../components/PaymentReveneuPage/PaymentReveneuPage";

function PaymentReveneu() {
  return (
    <div className="chats">
      <HomepageNavBar />
      <SettingsHeader heading="Payment & Revenue " />
      <div
        className="d-flex"
        style={{ backgroundColor: "#D9D9D9", marginTop: 10 }}
      >
        <SettingsLeftPanel tab={1} />
        <div>
          <PaymentReveneuPage />
        </div>
      </div>
    </div>
  );
}

export default PaymentReveneu;
