import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

function EmailVerification1Page() {
  const navigate = useNavigate();

  const [digit1, setDigit1] = useState("");
  const [digit2, setDigit2] = useState("");
  const [digit3, setDigit3] = useState("");
  const [digit4, setDigit4] = useState("");

  const [popupVisible, setPopupVisible] = useState(true);

  const handleCancel = () => {
    setPopupVisible(false);
  };

  if (!popupVisible) {
    // navigate("/kyc");
    return null;
  }

  return (
    <div className="container" style={{ marginRight: -10 }}>
      <div className="col-md-12">
        <div className="justify-content-center align-items-center">
          <h4 className="code-title" style={{ fontSize: 16 }}>
            Please check your email
          </h4>
          <p className="code-desc" style={{ fontSize: 12 }}>
            We have sent a code to asd******@gmail.com
          </p>
          <div className="d-flex code">
            <input
              style={{ height: 50, width: 50 }}
              className="digit"
              // height="40px"
              // width="40px"
              // type="number"
              value={digit1}
              onChange={(e) => setDigit1(e.target.value)}
              maxLength="1"
            />
            <input
              style={{ height: 50, width: 50 }}
              className="digit"
              // height="40px"
              // width="40px"
              // type="number"
              value={digit2}
              onChange={(e) => setDigit2(e.target.value)}
              maxLength="1"
            />
            <input
              style={{ height: 50, width: 50 }}
              className="digit"
              // height="40px"
              // width="40px"
              // type="number"
              value={digit3}
              onChange={(e) => setDigit3(e.target.value)}
              maxLength="1"
            />
            <input
              style={{ height: 50, width: 50 }}
              className="digit"
              // height="40px"
              // width="40px"
              // type="number"
              value={digit4}
              onChange={(e) => setDigit4(e.target.value)}
              maxLength="1"
            />
          </div>
          <p
            className="resend-code"
            style={{ fontSize: 14, color: "black", fontFamily: "GilroyLight" }}
          >
            Didn't get a code?{" "}
            <a href="" style={{ color: "gray" }}>
              Click to resend
            </a>
          </p>
          <div className="d-flex col-md-12" style={{ marginBottom: 20 }}>
            <button
              onClick={handleCancel}
              // onClick={() => navigate("/kyc")}
              className="col-md-5 verify-button"
              style={{
                border: "1px solid #D2D4C8",
                borderColor: "#D2D4C8",
                backgroundColor: "white",
                marginRight: 10,
                fontFamily: "GilroyLight",
              }}
            >
              Cancel
            </button>
            <button
              onClick={() => navigate("/emailverification2")}
              className="col-md-5 verify-button"
              style={{ fontFamily: "GilroyLight" }}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification1Page;
