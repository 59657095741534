import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Screen1 from "./Pages/SignupScreens/SignUp/Screen1";
import Screen2 from "./Pages/SignupScreens/EmailVerification/Screen2";
import ProfileSetup from "./Pages/SignupScreens/ProfileSetup1/ProfileSetup";
import ProfileSetup2 from "./Pages/SignupScreens/ProfileSetup2/ProfileSetup2";
import ProfileSetup3 from "./Pages/SignupScreens/ProfileSetup3/ProfileSetup3";
import Homepage1 from "./Pages/Homepage1/Homepage1";
import Homepage2 from "./components/Homepage2";
import FindCollab from "./Pages/FindCollab/FindCollab";
import CollabDetails from "./Pages/CollabDetails/CollabDetails";
import LandingPage from "./Pages/LandingPage/LandingPage";
import CollabStatus from "./Pages/CollabStatus/CollabStatus";
import CollabStatusDs from "./Pages/CollabStatusDs/CollabStatusDs";
import CollabStatusCs from "./Pages/CollabStatusCs/CollabStatusCs";
import Profile from "./Pages/Profile/Profile";
import CollabCompleted from "./Pages/CollabCompleted/CollabCompleted";
import PostCollab from "./Pages/PostCollab/PostCollab";
import Chats from "./Pages/MessengerScreen-Chats/Chats";
import Communities from "./Pages/MessengerScreen-Communities/Communities";
import Settings from "./Pages/Settings/Settings";
import CommunityForum from "./Pages/CommunityForum/CommunityForum";
import ForumPost from "./Pages/ForumPostOpen/ForumPost";
import NotificationSetting from "./components/NotificationSettingPage/NotificationSetting";
import NotifSettingPage from "./Pages/NotificationsSetting/NotificationsSetting";
import PaymentReveneuPage from "./components/PaymentReveneuPage/PaymentReveneuPage";
import PaymentReveneu from "./Pages/PaymentAndRevenueu/PaymentAndReveneu";
import Security from "./Pages/Security/Security";
import KycVerificationPage from "./Pages/KycVerificationPage/KycVerificationPage";
import KycVerificationPage2 from "./Pages/KycVerificationPage2/KycVerificationPage2";
import EmailVerification1Page from "./components/EmailVerification1Page/EmailVerification1Page";
import EmailVerification2 from "./Pages/EmailVerification2/EmailVerification2";
import PhoneVerification from "./Pages/PhoneVerification/PhoneVerification";
import Nested from "./components/ForumPostOpenPage/Replies/Replies";
import UploadDocument from "./Pages/UploadDocument/UploadDocument";
import UploadVideo from "./Pages/UploadVideo/UploadVideos";
// import ForumPost from './Pages/ForumPost/ForumPost';

function App() {
  return (
    //<div></div>
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/signup" element={<Screen1 />} />
        <Route path="/verify" element={<Screen2 />} />
        <Route path="/profilesetup" element={<ProfileSetup />} />
        <Route path="/profilesetup2" element={<ProfileSetup2 />} />
        <Route path="/profilesetup3" element={<ProfileSetup3 />} />
        <Route path="/homepage1" element={<Homepage1 />} />
        <Route path="/homepage2" element={<Homepage2 />} />
        <Route path="/findcollab" element={<FindCollab />} />
        <Route path="/collabdetails" element={<CollabDetails />} />
        <Route path="/collabstatus" element={<CollabStatus />} />
        <Route path="/collabstatusds" element={<CollabStatusDs />} />
        <Route path="/collabstatuscs" element={<CollabStatusCs />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/collabcompleted" element={<CollabCompleted />} />
        <Route path="/postcollab" element={<PostCollab />} />
        <Route path="/chats" element={<Chats />} />
        <Route path="/communities" element={<Communities />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/communityforum" element={<CommunityForum />} />
        <Route path="/forumpost" element={<ForumPost />} />
        <Route path="/notificationsetting" element={<NotifSettingPage />} />
        <Route path="/payment" element={<PaymentReveneu />} />
        <Route path="/security" element={<Security />} />
        <Route path="/kyc" element={<KycVerificationPage />} />
        <Route path="/kyc2" element={<KycVerificationPage2 />} />
        <Route path="/emailverification2" element={<EmailVerification2 />} />
        <Route path="/phoneVerification" element={<PhoneVerification />} />
        <Route path="/uploaddoc" element={<UploadDocument />} />
        <Route path="/uploadvideo" element={<UploadVideo />} />
      </Routes>
    </Router>
  );
}

export default App;
