import React, { useState, useRef } from "react";
import upload from "../../Images/upload.png";

function UploadDocComponent(props) {
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragOver(false);

    const newFiles = Array.from(e.dataTransfer.files);
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  return (
    <div className="col-md-12 upload-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          className=" col-md-12
        text-center justify-content-center align-items-center upload-div"
          //  style={{ marginRight: '20px' }}
          //  onClick={handleFileClick}
        >
          <div
            className={`upload-div ${isDragOver ? "drag-over" : ""}`}
            onClick={handleFileClick}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{ paddingTop: 10, paddingBottom: 10 }}
          >
            <img
              style={{ marginTop: 10 }}
              src={upload}
              height="20"
              width="20"
            />
            <p style={{ fontFamily: "GilroyBold" }}>{props.title}</p>
            <p
              className="addfiletxt"
              style={{
                marginBottom: 0,
                paddingBottom: 0,
              }}
            >
              {props.desp}
            </p>
            <p className="addfiletxt" style={{ marginTop: 0, paddingTop: 0 }}>
              {props.supported}
            </p>
            <div className="d-flex click-txt">
              <p className="upload" style={{ marginTop: -20, marginBottom: 0 }}>
                Click to upload
              </p>
              <p
                className="dragdrop"
                style={{ marginTop: -20, marginBottom: 0 }}
              >
                or drag and drop
              </p>
            </div>
          </div>
        </div>

        <input
          type="file"
          accept=".jpg, .png, .svg, .gif" // Define accepted file types if needed
          style={{ display: "none" }} // Hide the file input
          ref={fileInputRef}
          onChange={handleFileChange}
        />
      </div>
    </div>
  );
}

export default UploadDocComponent;
