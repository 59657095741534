import React from "react";
import "../../CollabPage/CollabHeader/CollabHeader.css";

const CollabCompletedHeader = () => {
  return (
    <div className="collab__header">
      <h2 className="collab__heading">Collab Status</h2>
      <div className="submit__button">
        <h3>Completed</h3>
      </div>
    </div>
  );
};

export default CollabCompletedHeader;
