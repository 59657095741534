import React from "react";
import Form from "react-bootstrap/Form";
import {
  ToggleButtonGroup,
  ToggleButton,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "./ButtonElement.css";
import { Navigate, useNavigate } from "react-router-dom";

function ButtonElement(props) {
  const navigate = useNavigate();
  let btnClass = "";
  if (props.buttonType == "Verified") {
    btnClass = "verified";
  } else if (props.buttonType == "Submitted") {
    btnClass = "submitted";
  } else if (props.buttonType == "Failed") {
    btnClass = "failed";
  } else {
    btnClass = "regular";
  }

  const handleButtonClick = () => {
    if (props.title === "Identification Document") {
      navigate("/uploaddoc");
    }
  };

  return (
    <Form style={{ marginTop: 10 }}>
      <Row>
        <Col sm={10}>
          <Row>
            <label className="toogleEleTitle">{props.title}</label>
          </Row>
          <Row>
            <label
              style={{
                color: "#717171",
                fontSize: 12,
                fontFamily: "GilroyLight",
              }}
            >
              {" "}
              {props.desp}{" "}
            </label>
          </Row>
        </Col>
        <Col sm={2}>
          <div className="d-flex" style={{ marginTop: 10 }}>
            <button className={btnClass} onClick={handleButtonClick}>
              {props.buttonType}
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default ButtonElement;
