import React, { useState } from "react";
import "./SettingsLeftPanel.css";
import { Navigate, useNavigate } from "react-router-dom";

function SettingsLeftPanel(props) {
  const [activeRow, setActiveRow] = useState(props.tab);
  const navigate = useNavigate();

  const handleRowClick = (index) => {
    setActiveRow(index);

    if (index === 0) {
      navigate("/settings");
    } else if (index === 1) {
      navigate("/payment");
    } else if (index === 2) {
      navigate("/notificationsetting");
    } else if (index === 3) {
      navigate("/security");
    } else if (index === 4) {
      navigate("/kyc");
    }
  };

  return (
    <div className="settings-container">
      <table className="col-md-12" style={{ backgroundColor: "white" }}>
        <tr
          className={activeRow === 0 ? "active-tab" : ""}
          onClick={() => handleRowClick(0)}
        >
          <td className="p-2">Profile Setting</td>
        </tr>
        <tr
          className={activeRow === 1 ? "active-tab" : ""}
          onClick={() => handleRowClick(1)}
        >
          <td className="p-2">Payment & Revenue</td>
        </tr>
        <tr
          className={activeRow === 2 ? "active-tab" : ""}
          onClick={() => handleRowClick(2)}
        >
          <td className="p-2">Notification</td>
        </tr>
        <tr
          className={activeRow === 3 ? "active-tab" : ""}
          onClick={() => handleRowClick(3)}
        >
          <td className="p-2">Security & Privacy</td>
        </tr>
        <tr
          className={activeRow === 4 ? "active-tab" : ""}
          onClick={() => handleRowClick(4)}
        >
          <td className="p-2">KYC Verification</td>
        </tr>
      </table>
    </div>
  );
}

export default SettingsLeftPanel;
