import React from "react";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup, ToggleButton, Row, Col } from "react-bootstrap";
function ButtonEle(props) {
  return (
    <Form style={{ marginTop: 10 }}>
      <Row>
        <Col sm={10}>
          <Row>
            <label className="toogleEleTitle">{props.title}</label>
          </Row>
          <Row>
            <label
              style={{
                color: "#717171",
                fontSize: 12,
                fontFamily: "GilroyLight",
              }}
            >
              {" "}
              {props.desp}{" "}
            </label>
          </Row>
        </Col>
        <Col sm={2}>
          <div className="d-flex" style={{ marginTop: 20 }}>
            <input
              className="border border-grey"
              style={{
                color: "#ccc",
                fontSize: 12,
                textAlign: "center",
                height: 20,
              }}
              placeholder="Mini"
            />
            <input
              className="border border-grey"
              style={{
                color: "#ccc",
                fontSize: 12,
                textAlign: "center",
                marginLeft: 3,
                height: 20,
              }}
              placeholder="Max"
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
}

export default ButtonEle;
