import React from "react";
import Form from "react-bootstrap/Form";
import { ToggleButtonGroup, ToggleButton, Row, Col } from "react-bootstrap";
import "./ToggleElement2.css";
function ToggleElement2(props) {
  return (
    <Form style={{ marginTop: 10 }}>
      <Row>
        <Col sm={10}>
          <Row>
            <label className="toogleEleTitle">{props.title}</label>
          </Row>
          <Row>
            <label
              style={{
                color: "#717171",
                fontSize: 12,
                fontFamily: "GilroyLight",
              }}
            >
              {" "}
              {props.desp}{" "}
            </label>
          </Row>
        </Col>
        <Col sm={2}>
          <Form.Check
            reverse
            // label = {props.title}
            className="tooglebtn"
            type="switch"
            id="custom-switch"
            checked={!props.isDisabled}
            onChange={props.onToggle}
            style={{
              borderColor: "#fee500",
              backgroundColor: "#fee500 !important",
              marginTop: 10,
            }}
          />
        </Col>
      </Row>
    </Form>
  );
}

export default ToggleElement2;
