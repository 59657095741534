import React from "react";
import HomepageNavBar from "../../components/Homepage1/Homepage1Navbar/HomepageNavBar";
import SettingsHeader from "../../components/SettingsPage/SettingsHeader/SettingsHeader";
import SettingsLeftPanel from "../../components/SettingsPage/SettingsPageLeftPanel/SettingsLeftPanel";
import KycVerification2 from "../../components/KycVerification/KycVerification2";

function KycVerificationPage2() {
  return (
    <div className="chats">
      <HomepageNavBar />
      <SettingsHeader heading="KYC Verification" />
      <div
        className="d-flex"
        style={{ backgroundColor: "#D9D9D9", marginTop: 10 }}
      >
        <SettingsLeftPanel />
        <div>
          <KycVerification2 />
        </div>
      </div>
    </div>
  );
}

export default KycVerificationPage2;
