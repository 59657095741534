import React from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCol,
  MDBContainer,
  MDBIcon,
  MDBRow,
  MDBTypography,
} from "mdb-react-ui-kit";
import "./Replies.css";

export default function Nested() {
  return (
    <section className="gradient-custom vh-100" style={{ marginTop: -40 }}>
      <MDBContainer className="py-5" style={{ maxWidth: "1000px" }}>
        <MDBRow className="justify-content-center">
          <MDBCol md="12" lg="10" xl="12">
            <MDBCard>
              <MDBCardBody className="p-4">
                <MDBRow>
                  <MDBCol>
                    <div className="d-flex flex-start">
                      <MDBCardImage
                        className="rounded-circle shadow-1-strong me-3"
                        src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(10).webp"
                        alt="avatar"
                        width="65"
                        height="65"
                      />

                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1 reply_name">Maria Smantha </p>
                            {/* <a href="#!">
                              <MDBIcon fas icon="reply fa-xs" />
                              <span className="small"> reply</span>
                            </a> */}
                            <span className="small reply_time">
                              {" "}
                              2 hours ago
                            </span>
                          </div>
                          <p className="small mb-0 reply">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nullam eget elit auctor, tincidunt Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit orem
                            ipsum dolor sit amet, casaf Lorem ipsum dolor sit
                            amet.
                          </p>
                          <div className="reply_btn" style={{ marginTop: 10 }}>
                            <span className="small" style={{ marginRight: 5 }}>
                              {" "}
                              Reply
                            </span>
                            <span
                              className="border border-grey small"
                              style={{ marginRight: 5 }}
                            >
                              👍 627
                            </span>
                            <span className="border border-grey small">
                              👎 1545
                            </span>
                          </div>
                        </div>

                        <div className="d-flex flex-start mt-4">
                          <a className="me-3" href="#">
                            <MDBCardImage
                              className="rounded-circle shadow-1-strong me-3"
                              src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(11).webp"
                              alt="avatar"
                              width="65"
                              height="65"
                            />
                          </a>

                          <div className="flex-grow-1 flex-shrink-1">
                            <div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-1 reply_name">Simona Disa </p>
                              </div>
                              <p className="small mb-0 reply">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Nullam eget elit auctor,
                                tincidunt Lorem ipsum dolor sit amet,
                                consectetur adipiscing elit orem ipsum dolor sit
                                amet, casaf Lorem ipsum dolor sit amet..
                              </p>
                              <div
                                className="reply_btn"
                                style={{ marginTop: 10 }}
                              >
                                <span
                                  className="small"
                                  style={{ marginRight: 5 }}
                                >
                                  {" "}
                                  Reply
                                </span>
                                <span
                                  className="border border-grey small"
                                  style={{ marginRight: 5 }}
                                >
                                  👍 627
                                </span>
                                <span className="border border-grey small">
                                  👎 1545
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-start mt-4">
                      <MDBCardImage
                        className="rounded-circle shadow-1-strong me-3"
                        src="https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(12).webp"
                        alt="avatar"
                        width="65"
                        height="65"
                      />

                      <div className="flex-grow-1 flex-shrink-1">
                        <div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="mb-1 reply_name">Natalie Smith </p>
                            <span className="small reply_time">
                              {" "}
                              2 hours ago
                            </span>
                          </div>
                          <p className="small mb-0 reply">
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Nullam eget elit auctor, tincidunt Lorem ipsum
                            dolor sit amet, consectetur adipiscing elit orem
                            ipsum dolor sit amet, casaf Lorem ipsum dolor sit
                            amet.
                          </p>
                          <div className="reply_btn" style={{ marginTop: 10 }}>
                            <span className="small" style={{ marginRight: 5 }}>
                              {" "}
                              Reply
                            </span>
                            <span
                              className="border border-grey small"
                              style={{ marginRight: 5 }}
                            >
                              👍 627
                            </span>
                            <span className="border border-grey small">
                              👎 1545
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </section>
  );
}
