export const activitiesData = [
  {
    title: "Title",
    content: "You Hear Me",
  },
  {
    title: "Collab By",
    content: "Sean Kim",
  },
  {
    title: "Duration",
    content: "12 Days : 4 Hours",
  },
];

export const socialsData = [
  {
    name: "Play On Music Protocol",
    icon: "/profile/music__icon.png",
    link: "https://www.facebook.com/",
    backgroundColor: "", // Specify the background color
  },
  {
    name: "X",
    icon: "/profile/x__icon.png",
    link: "https://twitter.com/",
    backgroundColor: "#1DA1F2", // Specify the background color
  },
  {
    name: "Telegram",
    icon: "/profile/telegram__icon.png",
    link: "https://www.instagram.com/",
    backgroundColor: "#0088CC", // Specify the background color
  },
  {
    name: "Discord",
    icon: "/profile/discord__icon.png",
    link: "https://www.linkedin.com/",
    backgroundColor: "#5865F2", // Specify the background color
  },
];

export const skillsData = [
  "Guitarist",
  "Music Production",
  "Luthier",
  "Improvisation",
  "Composer",
];

export const collabData = [
  {
    title: "Title",
    content: "You Are My Everything",
  },
  {
    title: "Artists",
    content: ["Sean Kim", "Asad Ali", "Mahnoor Ali"],
  },
  {
    title: "Rating",
    content: 5,
  },
  {
    title: "Revenue Distribution",
    content: [
      { Artist: "Sean Kim", Percentage: "60" },
      { Artist: "Asad Ali", Percentage: "20" },
      { Artist: "Mahnoor Ali", Percentage: "20" },
    ],
  },
  {
    title: "Completion Time",
    content: "12 Days: 4 Hours",
  },
];
