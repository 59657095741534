import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import UploadDocComponent from "./UploadDocComponent";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import "./UploadDocumentPage.css";

function UploadDocumentPage() {
  const navigate = useNavigate();
  const navigateToProfileSetup = () => {
    navigate("/profilesetup"); // Navigate to the About page
  };

  return (
    <div
      className="verif_container col-md-10 p-2"
      style={{
        marginTop: 20,
      }}
    >
      <div className="justify-content-center align-items-center ">
        <h4 className="code-title" style={{ fontSize: 16, marginTop: 0 }}>
          <br />
          Upload documents
        </h4>
        <p className="code-desc" style={{ fontSize: 12 }}>
          Please upload back and front side of the your government-issued
          identification document
        </p>
        <div className="col-md-6 d-flex code">
          <UploadDocComponent
            title="Front side of your document"
            desp="Upload front side of your document"
            supported="Supported: PNG, JPG or PDF"
          />

          <UploadDocComponent
            title="Back side of your document"
            desp="Upload back side of your document"
            supported="Supported: PNG, JPG or PDF"
          />
        </div>
        <Form className="p-3">
          <Form.Check
            style={{
              fontFamily: "GilroyLight",
              color: "black",
              fontSize: 12,
              borderColor: "black",
            }}
            checked
            type="checkbox"
            id="custom-checkbox"
            label="I confirm that I uploaded valid government-issued photo ID. 
        This ID include mypicture, signature, name date of birth, and address."
          />
        </Form>
        <button
          className="p-1 col-md-12 mt-3"
          style={{
            borderColor: "transparent",
            backgroundColor: "#fee500",
            color: "black",
            fontFamily: "GilroyBold",
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}

export default UploadDocumentPage;
